import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

function GameComponent() {
  const canvasRef = useRef(null);
  const [gameState, setGameState] = useState('start');
  const [highScore, setHighScore] = useState(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    let playerX = canvas.width / 2;
    let playerY = canvas.height - 30;
    let playerHealth = 100;
    let bullets = [];
    let enemyBullets = [];
    let enemies = [];
    let powerups = [];
    let score = 0;
    let lastShotTime = 0;
    let playerWeapon = 'normal';
    let boss = null;
    let bossPhase = 0;
    let damageEffect = 0;
    let bossSpawnScore = 1000;
    let level = 1;
    let combo = 0;
    let comboTimer = 0;

    function drawPlayer() {
      ctx.fillStyle = damageEffect > 0 ? 'red' : 'lime';
      ctx.beginPath();
      ctx.moveTo(playerX, playerY);
      ctx.lineTo(playerX - 15, playerY + 30);
      ctx.lineTo(playerX + 15, playerY + 30);
      ctx.closePath();
      ctx.fill();

      // Shield effect
      if (playerHealth > 50) {
        ctx.strokeStyle = 'rgba(0, 255, 255, 0.5)';
        ctx.beginPath();
        ctx.arc(playerX, playerY + 15, 25, 0, Math.PI * 2);
        ctx.stroke();
      }
    }

    function drawBullets() {
      bullets.forEach(bullet => {
        ctx.fillStyle = bullet.color;
        if (bullet.type === 'homing') {
          ctx.beginPath();
          ctx.arc(bullet.x, bullet.y, 3, 0, Math.PI * 2);
          ctx.fill();
        } else if (bullet.type === 'laser') {
          ctx.fillRect(bullet.x - bullet.width / 2, 0, bullet.width, canvas.height);
        } else {
          ctx.fillRect(bullet.x, bullet.y, 3, 10);
        }
      });
      ctx.fillStyle = 'orange';
      enemyBullets.forEach(bullet => {
        ctx.fillRect(bullet.x, bullet.y, 3, 10);
      });
    }

    function drawEnemies() {
      enemies.forEach(enemy => {
        ctx.fillStyle = enemy.color;
        if (enemy.type === 'normal') {
          ctx.fillRect(enemy.x, enemy.y, 30, 30);
        } else if (enemy.type === 'fast') {
          ctx.beginPath();
          ctx.moveTo(enemy.x, enemy.y + 30);
          ctx.lineTo(enemy.x + 15, enemy.y);
          ctx.lineTo(enemy.x + 30, enemy.y + 30);
          ctx.closePath();
          ctx.fill();
        } else if (enemy.type === 'homing') {
          ctx.beginPath();
          ctx.arc(enemy.x + 15, enemy.y + 15, 15, 0, Math.PI * 2);
          ctx.fill();
        }
        ctx.fillStyle = 'white';
        ctx.fillRect(enemy.x, enemy.y - 10, 30, 5);
        ctx.fillStyle = 'red';
        ctx.fillRect(enemy.x, enemy.y - 10, 30 * (enemy.health / enemy.maxHealth), 5);
      });
    }

    function drawBoss() {
      if (boss) {
        ctx.fillStyle = 'purple';
        ctx.fillRect(boss.x, boss.y, 100, 100);
        ctx.fillStyle = 'white';
        ctx.fillRect(boss.x, boss.y - 20, 100, 10);
        ctx.fillStyle = 'red';
        ctx.fillRect(boss.x, boss.y - 20, 100 * (boss.health / boss.maxHealth), 10);
      }
    }

    function drawPowerups() {
      powerups.forEach(powerup => {
        ctx.fillStyle = powerup.color;
        ctx.beginPath();
        ctx.arc(powerup.x, powerup.y, 10, 0, Math.PI * 2);
        ctx.fill();
      });
    }

    function drawUI() {
      ctx.fillStyle = 'white';
      ctx.font = '16px Arial';
      ctx.fillText('スコア: ' + score, 10, 20);
      ctx.fillText('武器: ' + playerWeapon, 10, 40);
      ctx.fillText('体力: ' + playerHealth, 10, 60);
      ctx.fillText('レベル: ' + level, 10, 80);
      ctx.fillText('コンボ: ' + combo, 10, 100);

      // Draw health bar
      ctx.fillStyle = 'red';
      ctx.fillRect(canvas.width - 110, 10, 100, 10);
      ctx.fillStyle = 'green';
      ctx.fillRect(canvas.width - 110, 10, playerHealth, 10);
    }

    function shoot() {
      const currentTime = Date.now();
      if (currentTime - lastShotTime > 400) {
        switch (playerWeapon) {
          case 'normal':
            bullets.push({x: playerX, y: playerY, damage: 10, color: 'yellow', type: 'normal'});
            break;
          case 'spread':
            for (let i = -2; i <= 2; i++) {
              bullets.push({x: playerX + i * 10, y: playerY, damage: 8, color: 'cyan', type: 'normal'});
            }
            break;
          case 'homing':
            bullets.push({x: playerX, y: playerY, damage: 15, color: 'red', type: 'homing', speed: 3});
            break;
          case 'laser':
            bullets.push({x: playerX, y: 0, damage: 5, color: 'purple', type: 'laser', width: 5});
            break;
        }
        lastShotTime = currentTime;
      }
    }

    function enemyShoot(enemy) {
      if (enemy.type === 'normal' && Math.random() < 0.02) {
        enemyBullets.push({x: enemy.x + 15, y: enemy.y + 30, speed: 3});
      }
    }

    function moveHoming(bullet) {
      let targetEnemy = null;
      let closestDistance = Infinity;

      enemies.forEach(enemy => {
        const dx = enemy.x - bullet.x;
        const dy = enemy.y - bullet.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < closestDistance) {
          closestDistance = distance;
          targetEnemy = enemy;
        }
      });

      if (targetEnemy) {
        const dx = targetEnemy.x - bullet.x;
        const dy = targetEnemy.y - bullet.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        bullet.x += (dx / distance) * bullet.speed;
        bullet.y += (dy / distance) * bullet.speed;
      } else {
        bullet.y -= bullet.speed;
      }
    }

    function bossAttack() {
      if (boss) {
        switch (bossPhase) {
          case 0:
            for (let i = 0; i < 5; i++) {
              enemyBullets.push({x: boss.x + 50 + (i - 2) * 20, y: boss.y + 100, speed: 3});
            }
            break;
          case 1:
            const dx = playerX - (boss.x + 50);
            const dy = playerY - (boss.y + 50);
            const angle = Math.atan2(dy, dx);
            enemyBullets.push({
              x: boss.x + 50,
              y: boss.y + 50,
              speed: 4,
              vx: Math.cos(angle) * 4,
              vy: Math.sin(angle) * 4
            });
            break;
          case 2:
            for (let i = 0; i < 8; i++) {
              const angle = (i / 8) * Math.PI * 2;
              enemyBullets.push({
                x: boss.x + 50,
                y: boss.y + 50,
                speed: 3,
                vx: Math.cos(angle) * 3,
                vy: Math.sin(angle) * 3
              });
            }
            break;
        }
        bossPhase = (bossPhase + 1) % 3;
      }
    }

    function spawnEnemy() {
      if (Math.random() < 0.02 + (level * 0.005) && !boss) {
        const enemyType = Math.random() < 0.6 ? 'normal' : (Math.random() < 0.8 ? 'fast' : 'homing');
        enemies.push({
          x: Math.random() * (canvas.width - 30),
          y: 0,
          type: enemyType,
          speed: enemyType === 'normal' ? 1 : (enemyType === 'fast' ? 3 : 2),
          color: enemyType === 'normal' ? 'red' : (enemyType === 'fast' ? 'orange' : 'purple'),
          points: enemyType === 'normal' ? 10 : (enemyType === 'fast' ? 20 : 30),
          health: enemyType === 'normal' ? 30 : (enemyType === 'fast' ? 20 : 40),
          maxHealth: enemyType === 'normal' ? 30 : (enemyType === 'fast' ? 20 : 40)
        });
      }
    }

    function spawnPowerup() {
      if (Math.random() < 0.001 + (level * 0.0002)) {
        const weaponTypes = ['spread', 'homing', 'laser'];
        const weaponType = weaponTypes[Math.floor(Math.random() * weaponTypes.length)];
        powerups.push({
          x: Math.random() * (canvas.width - 20),
          y: 0,
          weapon: weaponType,
          color: weaponType === 'spread' ? 'cyan' : (weaponType === 'homing' ? 'red' : 'purple')
        });
      }
    }

    function checkCollisions() {
      enemies.forEach(enemy => {
        bullets.forEach(bullet => {
          if (bullet.x > enemy.x && bullet.x < enemy.x + 30 &&
              bullet.y > enemy.y && bullet.y < enemy.y + 30) {
            enemy.health -= bullet.damage;
            if (enemy.health <= 0) {
              score += enemy.points * (1 + combo * 0.1);
              combo++;
              comboTimer = 180; // 3 seconds at 60 FPS
            }
            if (bullet.type !== 'laser') {
              bullet.hit = true;
            }
          }
        });
        if (enemy.x > playerX - 15 && enemy.x < playerX + 15 &&
            enemy.y > playerY - 15 && enemy.y < playerY + 30) {
          playerHealth -= 20;
          damageEffect = 10;
          enemy.health = 0;
          combo = 0;
        }
      });

      if (boss) {
        bullets.forEach(bullet => {
          if (bullet.x > boss.x && bullet.x < boss.x + 100 &&
              bullet.y > boss.y && bullet.y < boss.y + 100) {
            boss.health -= bullet.damage;
            if (boss.health <= 0) {
              score += 1000 * level;
              combo += 10;
              comboTimer = 180;
              boss = null;
              bossSpawnScore += 1000;
              level++;
            }
            if (bullet.type !== 'laser') {
              bullet.hit = true;
            }
          }
        });
      }

      powerups.forEach(powerup => {
        if (playerX > powerup.x - 20 && playerX < powerup.x + 20 &&
            playerY > powerup.y - 20 && playerY < powerup.y + 20) {
          playerWeapon = powerup.weapon;
          powerup.hit = true;
        }
      });
    }

    function update() {
      if (gameState === 'playing') {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        drawPlayer();
        drawBullets();
        drawEnemies();
        drawBoss();
        drawPowerups();
        drawUI();

        shoot();
        spawnEnemy();
        spawnPowerup();

        bullets = bullets.filter(bullet => {
          if (bullet.type === 'homing') {
            moveHoming(bullet);
          } else if (bullet.type === 'laser') {
            // Laser stays in place
          } else {
            bullet.y -= 5;
          }
          return bullet.y > 0 && bullet.y < canvas.height && bullet.x > 0 && bullet.x < canvas.width && !bullet.hit;
        });

        enemyBullets = enemyBullets.filter(bullet => {
          if (bullet.vx !== undefined && bullet.vy !== undefined) {
            bullet.x += bullet.vx;
            bullet.y += bullet.vy;
          } else {
            bullet.y += bullet.speed;
          }
          if (bullet.x > playerX - 15 && bullet.x < playerX + 15 &&
              bullet.y > playerY - 15 && bullet.y < playerY + 30) {
            playerHealth -= 10;
            damageEffect = 10;
            combo = 0;
            return false;
          }
          return bullet.y < canvas.height && bullet.x > 0 && bullet.x < canvas.width;
        });

        enemies = enemies.filter(enemy => {
          if (enemy.type === 'homing') {
            const dx = playerX - enemy.x;
            const dy = playerY - enemy.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            enemy.x += (dx / distance) * enemy.speed;
            enemy.y += (dy / distance) * enemy.speed;
          } else {
            enemy.y += enemy.speed;
          }
          enemyShoot(enemy);
          return enemy.y < canvas.height && enemy.health > 0;
        });

        powerups = powerups.filter(powerup => {
          powerup.y += 1;
          return powerup.y < canvas.height && !powerup.hit;
        });

        checkCollisions();

        if (score >= bossSpawnScore && !boss) {
          boss = {
            x: canvas.width / 2 - 50,
            y: 50,
            health: 1000 * level,
            maxHealth: 1000 * level
          };
        }

        if (boss && Math.random() < 0.05) bossAttack();

        if (damageEffect > 0) {
          damageEffect--;
        }

        if (comboTimer > 0) {
          comboTimer--;
        } else {
          combo = 0;
        }

        if (playerHealth <= 0) {
          setGameState('gameover');
          if (score > highScore) {
            setHighScore(score);
          }
        }
      } else if (gameState === 'start') {
        ctx.fillStyle = 'white';
        ctx.font = '30px Arial';
        ctx.textAlign = 'center';
        ctx.fillText('スペースシューター', canvas.width / 2, canvas.height / 2 - 50);
        ctx.font = '20px Arial';
        ctx.fillText('クリックしてスタート', canvas.width / 2, canvas.height / 2 + 50);
      } else if (gameState === 'gameover') {
        ctx.fillStyle = 'white';
        ctx.font = '30px Arial';
        ctx.textAlign = 'center';
        ctx.fillText('ゲームオーバー', canvas.width / 2, canvas.height / 2 - 50);
        ctx.font = '20px Arial';
        ctx.fillText('スコア: ' + score, canvas.width / 2, canvas.height / 2);
        ctx.fillText('ハイスコア: ' + highScore, canvas.width / 2, canvas.height / 2 + 30);
        ctx.fillText('クリックして再スタート', canvas.width / 2, canvas.height / 2 + 80);
      }

      requestAnimationFrame(update);
    }

    function handleMouseMove(event) {
      const rect = canvas.getBoundingClientRect();
      playerX = event.clientX - rect.left;
      playerY = event.clientY - rect.top;
      
      playerX = Math.max(15, Math.min(playerX, canvas.width - 15));
      playerY = Math.max(15, Math.min(playerY, canvas.height - 30));
    }

    function handleClick() {
      if (gameState === 'start' || gameState === 'gameover') {
        setGameState('playing');
        playerHealth = 100;
        score = 0;
        bullets = [];
        enemyBullets = [];
        enemies = [];
        powerups = [];
        playerWeapon = 'normal';
        boss = null;
        bossSpawnScore = 1000;
        level = 1;
        combo = 0;
      }
    }

    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('click', handleClick);
    update();

    return () => {
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('click', handleClick);
    };
  }, [gameState, highScore]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#000' }}>
      <canvas ref={canvasRef} width="400" height="600" style={{ border: '2px solid white' }} />
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <GameComponent />
    </div>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

export default App;